<div class="tw-overflow-hidden tw-relative tw-h-[180px] tw-mx-auto"
     id="imgHolder"
     #imgHolder=""
     *ngIf='chat && !(chat.get("imageDeleted") || chat.get("unableToFetch"))'
     (click)="openImage()"
     [ngClass]=" { 'tw-rounded-none': inMultiView } ">
  <loading *ngIf="loading"></loading>
  <canvas class="tw-w-auto tw-m-0 tw-h-[190px] tw-block tw-absolute tw-z-[1]" id="chatImageCanvas"
          #canvas=""
          [ngStyle]=" { 'visibility': loading ? 'hidden' : 'visible' }"></canvas>
</div>
<div class="tw-px-4 tw-pt-4"
     *ngIf="chat && chat.get('imageDeleted') && !inMultiView"
     i18n="@@thisImageDeleted">
  This image has been deleted.
</div>
<div class="tw-px-4 tw-pt-4"
     *ngIf="chat && chat.get('unableToFetch') && !inMultiView"
     i18n="@@unableToFetchImage">
  Unable to load images due to slow internet.
</div>
<div class="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-bg-white tw-z-10"
     *ngIf="images.length && chat && !(chat.get('imageDeleted') || chat.get('unableToFetch'))"
     (click)="expandImages.emit()">
  <div class="tw-w-12 tw-h-7 tw-absolute tw-top-1 tw-right-1 tw-z-[1] tw-text-white tw-text-center tw-text-100 tw-leading-7 tw-rounded-5 tw-flex tw-items-center tw-p-2 tw-font-bold">
    <em class="tw-text-white tw-text-400 tw-pr-1" class="cs-icon-camera"></em><span>+{{images.length}}</span>
  </div>
</div>