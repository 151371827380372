<div [ngClass]="{
    'tw-flex tw-flex-col tw-flex-nowrap tw-h-full tw-items-center tw-justify-center tw-transition-all tw-duration-500 tw-p-5': true,
    'tw-bg-teal-400': reminder?.get('type') === 'FULL_PAGE_POPUP',
    'tw-backdrop-blur-lg tw-bg-[#00000020]': reminder?.get('type') === 'POPUP'
  }">
  <img class="tw-max-w-full"
       [src]="reminder?.get('image')" />
  <button class="tw-min-w-unset tw-w-12 tw-absolute tw-top-6 tw-right-6 tw-bg-black tw-text-white"
          (click)="close()">
    <em class="icon cs-icon-close"></em>
  </button>
</div>