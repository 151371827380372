import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'app-reminder-full-page',
  templateUrl: './reminder-full-page.component.html',
  styles: ':host {@apply tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-z-[150]}',
  standalone: false,
})
export class ReminderFullPageComponent {
  reminder: any;
  constructor(
    private router: Router,
    private windowService: WindowRefService,
    private route: ActivatedRoute,
    private conn: ConnectionService,
    private eventLogger: EventLoggerService,
  ) {
    const reminderId = this.route.snapshot.paramMap.get('id');
    if (reminderId) {
      (async (): Promise<void> => {
        this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ reminderId, pageName: 'reminder' }));
        this.reminder = await this.conn.fetchReminderById(reminderId);
      })();
    }
  }

  close(): void {
    // const { history }: any = this.windowService.getWindow().window;
    // if (history.length === 2) {
    this.router.navigate([{ outlets: { reminder: null } }]);
    // } else {
    //   history.back();
    // }
  }
}
