<loading class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
         *ngIf="!pages.length"></loading>
<div class="tw-overflow-hidden tw-bg-white tw-font-body tw-h-full tw-w-full">
  <div class="flex tw-flex-col tw-h-full tw-w-full">
    <div class="tw-flex-none tw-w-full">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
             (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50"
                   [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white"
              i18n="@@shareYourFeedback">Share your feedback</span>
      </div>
    </div>
    <div class="tw-w-full tw-h-2.5 tw-bg-gray-200"
         #progressBar="">
      <div class="tw-bg-teal-400 tw-h-full tw-transition-all tw-duration-1000"
           style="width: 0;"
           #colouredBar=""></div>
    </div>
    <div class="tw-flex-1 tw-p-5 tw-flex tw-inset-y-auto tw-overflow-x-hidden tw-relative tw-w-full"
         *ngIf="currQuestionIndex >= 0 && currentPageIndex >= 0">
      <div class="tw-animate-fade_in tw-w-full"
           *ngFor="let row of pages[currentPageIndex];">
        <div class="tw-text-gray-800 tw-text-center tw-text-500 tw-mt-32 tw-mx-10 tw-mb-6 tw-max-w-full">
          {{row.question.question}}
        </div>
        <!-- Rate the app from 1 to 10 question -->
        <div *ngIf='row.question.type === "SCORE"'
             class="tw-flex tw-items-center tw-justify-center tw-w-full tw-max-w-[400px] tw-flex-wrap tw-mx-auto">
          <div class="tw-flex tw-items-center tw-px-3 justify-between tw-w-full">
            <span class="tw-text-100 tw-uppercase tw-tracking-wide"
                  i18n="@@notLikely">Not likely</span>
            <span class="tw-text-100 tw-uppercase tw-tracking-wide"
                  i18n="@@extremelyLikely">Extremely likely</span>
          </div>
          <div class="tw-flex tw-flex-nowrap tw-items-center">
            <div class="tw-border tw-border-gray-200 tw-h-20 tw-pt-6"
                 *ngFor="let rating of ratingArray; let i = index"
                 [ngClass]='{ "tw-border-l": i==0, "tw-border-l-0": i!=0 }'
                 (click)="saveScore(i, row)">
              <cs-icon [iconName]="rating"></cs-icon>
              <div class="tw-m-auto tw-text-200 tw-text-gray-600 tw-text-center">{{i}}</div>
            </div>
          </div>
        </div>
        <div class="tw-my-5 tw-mx-0 tw-flex tw-items-center tw-justify-center tw-flex-col"
             *ngIf='["SINGLE_SELECT"].includes(row.question.type)'>
          <div class="tw-px-8 tw-py-3 tw-rounded-full tw-flex tw-m-1 tw-border tw-border-gray-200 tw-text-gray-600 tw-lowercase max-w-[80%] tw-text-400"
               *ngFor="let option of row.question.options; let i = index"
               [ngClass]="{ 
                  'anim-click': row.answers.includes(option), 
                  'tw-text-gray-500 tw-opacity-50': (row.answers.length && !row.answers.includes(option)),
                }"
               (click)="selectOption(option, row, $event)">
            {{option}}
          </div>
        </div>
        <div class="tw-my-5 tw-mx-0 tw-flex tw-items-center tw-justify-center tw-flex-row tw-flex-wrap"
             *ngIf='["MULTI_SELECT"].includes(row.question.type)'>
          <div class="tw-px-8 tw-py-3 tw-rounded-full tw-flex tw-m-1 tw-border tw-border-gray-200 tw-text-gray-600 tw-lowercase max-w-[80%] tw-text-400"
               *ngFor="let option of row.question.options"
               [ngClass]="{ 
                  'anim-click': row.answers.includes(option), 
                  'tw-bg-green-400 tw-text-white': row.answers.includes(option) && row.question.uniqueId === 'likedFeatureArray', 
                  'tw-bg-red-400 tw-text-white': row.answers.includes(option) && row.question.uniqueId === 'needImprovementArray', 
                  'tw-text-300 tw-flex tw-flex-row tw-flex-wrap': row.question.type === 'MULTI_SELECT' 
                }"
               (click)="selectOption(option, row)">
            {{option}}
          </div>
        </div>
        <div class="tw-my-5 tw-mx-0 tw-flex tw-items-center tw-justify-center tw-flex-col"
             *ngIf='row.question.type === "TEXT"'>
          <textarea class="tw-w-full tw-resize-none tw-h-50 tw-rounded-2.5 tw-p-2.5 tw-min-h-24 tw-border tw-border-gray-200"
                    placeholder="Write us here"
                    (keyup)="textFeedback($event, row)"
                    i18n-placeholder="@@writeUsHere"></textarea>
        </div>
        <div class="tw-my-5 tw-mx-0 tw-flex tw-items-center tw-justify-center tw-flex-row tw-flex-wrap"
             *ngIf='row?.question?.type === "MULTI_SELECT" && row?.question?.category === "NPD"'>
          <div class="tw-flex tw-flex-col tw-gap-1">
            <div class="tw-flex tw-items-center tw-gap-2 tw-border tw-border-gray-100 tw-p-2 tw-pr-4 tw-rounded-2xl tw-max-w-full tw-justify-start"
                 *ngFor="let product of products"
                 [ngClass]="{
                    'anim-click': row.answers.includes(product.objectId),
                    'tw-bg-green-400 tw-text-white': row.answers.includes(product.objectId) && row.question.uniqueId === 'productLike',
                    'tw-bg-red-400 tw-text-white': row.answers.includes(product.objectId) && row.question.uniqueId === 'productDislike',
                    'tw-text-300 tw-flex tw-flex-row tw-flex-wrap': row.question.type === 'MULTI_SELECT'
                  }"
                 (click)="selectOption(product.objectId, row)">
              <img class="tw-h-10 tw-w-auto tw-rounded-md"
                   [src]="product?.rebrandedImageWithBackground?.[0]" />
              <p class="tw-font-body tw-text-300">{{product.title}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-flex-none tw-p-5 tw-w-full">
      <div class="text-center"></div>
      <div *ngIf="isLastPage()">
        <cs-button [class]="'primary w100'"
                   (callback)="submitFeedback()"
                   [disabled]="disableNextButton"><cs-button-text i18n="@@next"
                          *ngIf="isMultiSelectPage()">Next</cs-button-text><cs-button-text i18n="@@submit"
                          *ngIf="currentPageIndex === pages?.length - 1">Submit</cs-button-text></cs-button>
      </div>
    </div>
  </div>
</div>