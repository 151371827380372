<loading class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
         *ngIf="inProcess">
</loading>
<ng-container *ngIf="question && !inProcess">
  <section class="tw-whitespace-pre-line tw-break-words tw-p-5 tw-bg-white"
           *ngIf="question.outputComponent === appConfig.Shared.Support.component.HTML"
           [innerHTML]="domSanitizer.bypassSecurityTrustHtml(question.responseHtml)">
  </section>
  <product-list *ngIf="question.outputComponent === appConfig.Shared.Support.component.REGIMEN_PRODUCT_LIST"
                [regimen]="question.response?.regimen"></product-list>
  <regimen-instruction *ngIf="question.outputComponent === appConfig.Shared.Support.component.REGIMEN_INSTRUCTION"
                       [regimen]="question.response?.regimen"
                       [experiments]="experiments$ | async"></regimen-instruction>
  <order-update *ngIf="question.outputComponent === appConfig.Shared.Support.component.ORDER_UPDATE"
                [order]="question.response?.order"></order-update>
  <shop-by-category
                    *ngIf="question.outputComponent === appConfig.Shared.Support.component.PLACE_NEW_ORDER"></shop-by-category>
  <get-support-query *ngIf="question.outputComponent === appConfig.Shared.Support.component.GET_QUERY"
                     [message]="question.responseHtml"
                     [question]="question"></get-support-query>
  <doctor-profile
                  *ngIf="question.outputComponent === appConfig.Shared.Support.component.ABOUT_MY_DOCTOR"></doctor-profile>
  <support-regimen-class
                         *ngIf="question.outputComponent === appConfig.Shared.Support.component.REGIMEN_CLASS_SELECTION"></support-regimen-class>
  <support-view-edit-order
                           *ngIf="question.outputComponent === appConfig.Shared.Support.component.VIEW_EDIT_ORDER"></support-view-edit-order>
  <section class="tw-whitespace-pre-line tw-p-5 tw-bg-white"
           *ngIf="question.outputComponent === appConfig.Shared.Support.component.SELECT_SUB_QUERY">
    <ng-container *ngFor="let question of questions; let i = index">
      <div class="tw-flex tw-flex-row tw-items-center tw-py-4 tw-px-5 tw-mb-2.5"
           (click)="openQuestion(question, i)"
           [ngClass]='{ "tw-mb-0 tw-border-b tw-border-gray-200": questionMap[i]?.expanded }'>
        <em class="tw-h-9 tw-w-9 tw-rounded-full tw-bg-gray-100 tw-text-gray-700 tw-text-600"
            [ngClass]='question.iconClassName || "cs-icon-info"'></em>
        <div class="tw-flex-1 tw-flex tw-items-center tw-justify-start tw-px-2.5">{{question.listView ||
          question.question}}</div>
        <em class="cs-icon-arrow"
            [ngClass]="{ 'cs-icon-plus': question.listViewType === 'Expand', 'cs-icon-arrow': question.listViewType === 'Next' }"></em>
      </div>
      <div class="tw-overflow-y-hidden tw-overflow-x-auto tw-whitespace-nowrap"
           [ngClass]='{ "tw-bg-white tw-mb-0": questionMap[i]?.expanded }'>
        <ng-container *ngIf="questionMap[i] && questionMap[i].expanded">
          <support-component-selection [loading]="questionMap[i].inProcess"
                                       [question]="questionMap[i].question"
                                       [questions]="questionMap[i].questions"></support-component-selection>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="user?.isPaid()">
      <div class="tw-flex tw-flex-row tw-items-center tw-py-4 tw-px-5 tw-mb-2.5"
           (click)="viewAllQuery()">
        <em class="tw-h-9 tw-w-9 tw-rounded-full tw-bg-gray-100 tw-text-gray-700 tw-text-600"
            [ngClass]='"cs-icon-prescription"'></em>
        <div class="tw-flex-1 tw-flex tw-items-center tw-justify-start tw-px-2.5"
             i18n="@@allQueries">All Queries</div>
        <em class="cs-icon-arrow"></em>
      </div>
    </ng-container>
  </section>
</ng-container>